export const locale = {
  lang: 'ar',

  data: {
    ORDER_NUMBER: 'رقم الطلب',
    ORDER_DATE: 'تاريخ الطلب',
    BY_CUSTOMER: 'من قبل الزبون',
    ORDER_AMOUNT: 'كمية الطلب',
    ORDER_ITEMS: 'محتويات الطلب',
    ORDER_STATE: 'حالة الطلب',
    DELIVERY_DATE: 'تاريخ التوصيل',
    ORDER_DETAILS: 'تفاصيل الطلب',
    MERCHANT_NAME: 'اسم التاجر',
  },
};
