export const locale = {
  lang: 'ar',

  data: {
    BILL_DETAILS: 'تفاصيل الفاتورة',
    BILL_NUMBER: 'رقم الفاتورة',
    BILL_OWNER: 'صاحب الفاتورة',
    BILL_DATE: 'تاريخ الفاتورة',
    BILL_TIME: 'وقت الفاتورة',
    NUMBER_OF_PRODUCTS_IN_BILL: 'عدد المنتجات في الفاتورة',
    BILL_PRICE: 'قيمة الفاتورة',
    NET_PROFIT: 'صافي الربح',
    BILL_STATUS: 'حالة الفاتورة',
    TOTAL: 'الإجمالي',
  },
};
