export const locale = {
  lang: "ar",
  data: {
    ERROR_INTERCEPTOR: {
      UKNOWN_ERROR_TITLE: "فشل الاتصال",
      UKNOWN_ERROR_MESSAGE: "يرجى التأكد من اتصالك بالإنترنت والمحاولة من جديد",
      ERROR500_TITLE: "خطأ سيرفر",
      ERROR500_MESSAGE:
        "حدث خطأ على السيرفر، إذا تكرر الخطأ يرجى التواصل مع الإدارة",
    },
    IDLE_TIMEOUT: {
      LOGOUTING: "جاري تسجيل المغادرة...",
      LOGOUT_MESSAGE: "سيتم تسجيل المغادرة خلال ",
      LESS_THAN_MINUTE: "أقل من دقيقة",
    },
      
    COMPANY_CATEGORY: 'تصنيف الشركة',
    COMPANY_SECOND_NAME: 'اسم الشركة التجاري',
    COMPANY_MAIN_NAME: 'اسم الشركة الرئيسي',
  },
};
