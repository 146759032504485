export const appRoutesConstants = {
  dashboard: "/dashboard",
  productsManagement: "/products-management",
  ordersManagement: "/orders-management",
  usersManagement: "/users-management",
  billsManagement: "/bills-management",
  payments: "/payments",
  chats: "/chats",
  notifications: "/notifications",
  plans: "/plans",

  notAuthorized: "/miscellaneous/not-authorized",
  pageNotFound: "/miscellaneous/error",

  login: "/auth/login",
  forgotPassword: "/auth/forgot-password",
  resetPassword: "/auth/reset-password",
  confirmEmail: "/auth/confirm-email",
  register: "/auth/register",
  confirmPhone: "/auth/confirm-phone",

  accountSettings: "/account-settings",

  settings: "/settings",
};
