import { CoreMenu } from '@core/types';
import { faBell, faBoxesStacked, faBriefcase, faCartShopping, faChartLine, faChartPie, faCog, faMessage, faReceipt, faRectangleAd, faRightFromBracket, faShop, faUserShield, faUsers } from '@fortawesome/free-solid-svg-icons';
import { appRoutesConstants } from 'app/@core/values/app-routes-constants';


export const menu: CoreMenu[] = [
  {
    id: 'dashboard',
    type: 'item',
    title: 'الرئيسية',
    translate: 'MENU.DASHBOARD',
    svgIcon: 'assets/icons/white/Graph.svg',
    url: appRoutesConstants.dashboard,
  },
  {
    id: 'productsManagement',
    title: 'إدارة المنتجات',
    translate: 'MENU.PRODUCTS_MANAGEMENT',
    type: 'item',
    svgIcon: 'assets/icons/white/Image.svg',
    url: appRoutesConstants.productsManagement,
    // role: [authenticationConstants.roles.admin]
  },
  {
    id: 'ordersManagement',
    title: 'إدارة الطلبات',
    translate: 'MENU.ORDERS_MANAGEMENT',
    type: 'item',
    svgIcon: 'assets/icons/white/Buy.svg',
    url: appRoutesConstants.ordersManagement,
    // role: [authenticationConstants.roles.admin]
  },
  {
    id: 'users',
    title: 'إدارة المستخدمين',
    translate: 'MENU.USERS_MANAGEMENT',
    type: 'item',
    svgIcon: 'assets/icons/white/3 User.svg',
    url: appRoutesConstants.usersManagement,
    // role: [authenticationConstants.roles.admin]
  },
  {
    id: 'billsManagement',
    title: 'إدارة الفواتير',
    translate: 'MENU.BILLS_MANAGEMENT',
    type: 'item',
    svgIcon: 'assets/icons/white/Wallet.svg',
    url: appRoutesConstants.billsManagement,
    // role: [authenticationConstants.roles.admin]
  },
  {
    id: 'paymentsManagement',
    title: 'الحركات المالية',
    translate: 'MENU.PAYMENTS',
    type: 'item',
    svgIcon: 'assets/icons/white/Vector.svg',
    url: appRoutesConstants.payments,
    // role: [authenticationConstants.roles.admin]
  },
  {
    id: 'chats',
    title: 'المحادثات',
    translate: 'MENU.CHATS',
    type: 'item',
    svgIcon: 'assets/icons/white/Chat.svg',
    url: appRoutesConstants.chats,
    // role: [authenticationConstants.roles.admin]
  },
  {
    id: 'notifications',
    title: 'الإشعارات',
    translate: 'MENU.NOTIFICATIONS',
    type: 'item',
    svgIcon: 'assets/icons/white/Notification.svg',
    url: appRoutesConstants.notifications,
    // role: [authenticationConstants.roles.admin]
  },
  {
    id: 'settings',
    title: 'الإعدادات',
    translate: 'MENU.SETTINGS',
    type: 'item',
    svgIcon: 'assets/icons/white/Setting.svg',
    url: appRoutesConstants.settings,
    // role: [authenticationConstants.roles.admin]
  },
  {
    id: 'logout',
    title: 'تسجل الخروج',
    translate: 'MENU.LOGOUT',
    type: 'item',
    svgIcon: 'assets/icons/white/Logout.svg',
    url: appRoutesConstants.login,
    // role: [authenticationConstants.roles.admin]
  },
];
// 1 - Do the menu buttons
// 2 - make a module for the users
// 3 - get the user type from the route 
// 4 - adjust the view according to the user type