export const locale = {
  lang: 'ar',

  data: {
    SATURDAY: 'السبت',
    SUNDAY: 'الأحد',
    MONDAY: 'الأثنين',
    TUESDAY: 'الثلاثاء',
    WEDNESDAY: 'الأربعاء',
    THURSDAY: 'الخميس',
    FRIDAY: 'الجمعة',
    
    TITLE: 'كسب | Kasb',
		
		MALE: 'ذكور',
		FEMALE: 'إناث',

		NAME: 'اسم المستخدم',
		NAME_REQUIRED: 'الرجاء إدخال الاسم.',
		
    COLOR: 'لون المتجر',

    CREATED_DATE: 'تاريخ الدخول',
    ACCOUNT_STATE: 'حالة الحساب',
    ACCOUNT_TYPE: 'نوع الحساب',

    PHONE_NUMBER: 'رقم الموبايل',
    PHONE_NUMBER_REQUIRED: 'الرجاء إدخال رقم الموبايل.',
    
		PASSWORD: 'كلمة المرور',
		PASSWORD_REQUIRED: 'الرجاء إدخال كلمة المرور.',
    PASSWORD_INVALID: 'يجب ان تحوي كلمة المرور على حرف صغبر, حرف كبير, رقم و رمز ع الأقل',
    
    EMAIL: 'البريد الألكتروني',
    EMAIL_REQUIRED: 'الرجاء إدخال الإيميل.',

    SUCCESS: 'نجاح',
    WARNING: 'تنبيه',
    ERROR: 'خطأ',
    OPERATION_FAILED: 'فشلت العملية',
    OPERATION_SUCCEEDED: 'تمت العملية بنجاح',

		ITEM_STATUS: 'حالة العنصر',

		ALL: 'الكل',
		SHOW_ALL: 'إظهار الكل',
		DELETED: 'سلة المحذوفات',
		NOT_DELETED: 'العناصر المتاحة',

    CONFIRMATION: 'تأكيد!',
    DELETE_QUESTION_CONFIRMATION: 'سوف يتم حذف السؤال من النماذج التالية:',
    DELETE_SUBJECT_CONFIRMATION: 'سوف يتم حذف المادة من الأماكن التالية:',
    DELETE_CONFIRMATION: 'هل أنت متأكد من عملية الحذف؟',
    RESTORE_CONFIRMATION: 'هل أنت متأكد من عملية الإستعادة؟',
    OK: 'موافق',

    LOADING: 'جاري تحميل البيانات...',

    INVENTORY: 'جرد الدفعة',
    MONTHPAYMENT: 'دفعات هذا الشهر',
    NOTPAID: 'الطلاب غير المسددين',
    PARRECEIPT: 'إيصال دفع',
    PAYMENT: 'سجل الدفعات',

    // Paging
    FROM: 'من',
    TO: 'إلى',
    ITEM: 'عنصر',

    NO_DATA_FOUND: 'لا توجد بيانات لعرضها',
    DONE: 'تَم',

    ACTIONS: 'الخيارات',

    SAVE: 'حفظ',
    SAVE_AS_COPY: 'حفظ كنسخة',
    NEW: 'جديد',
    CANCEL: 'إلغاء',

    DATE: 'التاريخ',
    SEARCH_HERE: 'ابحث هنا...',
    SEARCH: 'بحث',
    CANCEL_SEARCH: 'إلغاء البحث',

    FILES: 'المرفقات',
    UPLOAD_PHOTO: 'تحميل صورة',
    UPLOAD_FILE: 'إرفاق ملف',
    RESET: 'إزالة',
    UPLOAD_HELPER_TEXT: 'الحجم الأعظمي: 800 ك.ب',
    UPLOADING: 'جاري التحميل...',
    UPLOAD_DONE: 'تم التحميل',
    UPLOAD_FAILED: 'فشل التحميل',

    NOTES: 'الملاحظات',

    EMAIL_INVALID: '- يرجى التأكد من إدخال بريدك بشكل صحيح',

    REGISTRATION_REQUIRED: 'الرجاء إدخال وثيقة العمل',
    STREET_REQUIRED: 'الرجاء إدخال الشارع',
    CITY_REQUIRED: 'الرجاء إدخال الموقع الجغرافي',
    ZIP_CODE_REQUIRED: 'الرجاء إدخال ال zipCode',

    USER_NAME: 'اسم المستخدم',

		EDIT_MENU: 'قائمة التعديل',

    EDIT: 'تعديل',
    DELETE: 'حذف',
		RESTORE: 'إستعادة',

    EXECUTE: 'تنفيذ',
    PRINT: 'طباعة',

    YEAR: 'السنة',
    MONTH: 'الشهر',
    DAY: 'اليوم',

    STUDY_YEAR:'السنة الدراسية',

    HOUR: 'الساعة',
    MINUTES: 'الدقائق',

    CURRENCY: 'ل.س',
    CURRENCY_EN: 'SYP',
    PAID_TO_MR: 'دفعت إلى السيد',
    GENTLEMAN: 'المحترم', 
    AN_AMOUNT_OF: 'مبلغاً و قدره',
    FOR_COURSE: 'وذلك لقاء الدورة',
    IN_GROUP: 'في المجموعة',

    RECIPT: 'إيصال دفع',
    MARKS: 'العلامات',

    ATTENDANCE_TITLE: "التفقد",
    
    COST: 'التكلفة',

    VIRTUAL_NICK_NAME: 'العميل',
    COPY: 'نسخ',
    ID: 'المعرف',
    COPIED: 'تم النسخ',

    PLEASE_WAIT: 'الرجاء الإنتظار',

    PRICE: 'السعر',

    DONE_SUCCESSFULLY: 'تمت العملية بنجاح',

    UPLOAD_PHOTO_FAILED: 'فشل رفع الصورة',
    CLICK_TO_CHOSE_IMAGE: 'الرجاء اختيار صورة',
    EXPLANATION_PHOTO: 'الصورة',
    PHOTO: 'الصورة',

    UPLOAD_VIDEO_FAILED: 'فضل ارسال الفيديو',
    CLICK_TO_CHOOSE_VIDEO: 'الرجاء اختيار فيديو',
    EXPLANATION_VIDEO: 'الفيديو',
    VIDEO: 'الفيديو',

    UPLOAD_FILE_FAILED: 'فشل ارسال الملف',
    CLICK_TO_CHOOSE_FILE: 'الرجاء اختيار ملف',
    EXPLANATION_FILE: 'الملف',
    FILE: 'الملف',

    FREE: 'مجاني',
    AVAILABLE_WITH_PARENT: 'متاح مع الأب',
    NOT_AVAILABLE_WITH_PARENT: 'غير متاح مع الأب',
    
    ADD: 'إضافة',
    REMOVE: 'إزالة',

    YOU_CAN_SEARCH_BY_NAME: 'يمكنك البحث عن طريق الاسم',
    YOU_CAN_SEARCH_OR_ADD_BY_TAG_NAME: 'يمكنك البحث او الإضافة عن طريق اسم الوسم',

    DESCRIPTION: 'الوصف',
    BRIEF_DESCRIPTION: 'لمحة',
    DISPLAY_ORDER: 'ترتيب العرض',

    LEAVE_WITHOUT_SAVING: 'هل تريد المغادرة دون حفظ التغييرات؟',
    LEAVE: 'هل أنت متأكد من مغادرة الصفحة؟',

    AM: 'ص',
    PM: 'م',
    TODAY: 'اليوم',
  },
};
