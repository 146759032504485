import { Injectable } from '@angular/core';
import { AuthService } from 'app/features/auth/@core/utils/auth.service';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MediaPreviewService {
  // ------------------------------- Public Fields -------------------------------

  public previewImageChanged: BehaviorSubject<boolean>;
  public mediaSrc: string = '';
  public isImagePreview: boolean = true;
  public previewImageRef: boolean = false;

  // ------------------------------- Private Fields -------------------------------

  // ------------------------------- Constructor -------------------------------

  constructor(private _authService: AuthService) {
    this.previewImageChanged = new BehaviorSubject(false);
  }

  // ------------------------------- interface Resolve -------------------------------

  // ------------------------------- Public Functions -------------------------------

  public previewImage(value: any): void {
    this.previewImageRef = value;
    this.previewImageChanged.next(this.previewImageRef);
  }

  public getProtectedUrl(url: string): string {
    let resourcesUrl = environment.resourcesUrl,
      token = this._authService.currentUserValue
        ? this._authService.currentUserValue.token
        : '';

    return `${resourcesUrl}/${url}?accessToken=${token}`;
  }

  // ------------------------------- Private Functions -------------------------------
}
