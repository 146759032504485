<!-- Menu header -->
  <div class="navbar-header pt-2"
    [style.height.px]="detectMobile ? 188 : 188">
    @if (!isCollapsed) {
      @if (currentUser) {
        @if (currentUser.seller) {
          <div class="d-flex justify-content-center align-items-center pr-1 flex-column ">
            <div style="width: 100px; height: 100px; border-radius: 50%; border: 1px solid white;" class="d-flex justify-content-center align-items-center ">
              <div style="width: 90px; height: 90px; border-radius: 50%;" class="bg-secondary d-flex justify-content-center align-items-center ">
                @if (currentUser.seller.avatar) {
                  <img style="
                    width: 90px;
                    height: 90px;
                    object-fit: contain;
                    border-radius: 50%;" [src]="currentUser.seller.avatar.url" alt="">
                }
                @else {
                  <fa-icon class="text-primary " style="font-size: 20px;" [icon]="faCamera"></fa-icon>
                }
              </div>
            </div>
            <h3 class="text-white mt-1 mb-0 text-center">{{currentUser.seller.name}}</h3>
          </div>
        } 
      }
    }
    @if(detectMobile) {
      <a style="width: 33px; position: absolute; top: 10px; right: 10px;"
        class="nav-link modern-nav-toggle pr-0 d-none d-xl-block"
        (click)="toggleSidebarCollapsible()"
      >
        <i
          [ngClass]="isCollapsed === true ? 'icon-circle' : 'icon-disc'"
          class="toggle-icon feather font-medium-4 collapse-toggle-icon text-white"
        ></i>
      </a>
    }
    <a
      class="nav-link modern-nav-toggle d-block d-xl-none pr-0"
      (click)="toggleSidebar()"
    >
      <i data-feather="x" class="font-medium-4 text-white toggle-icon"></i>
    </a>
  </div>
<!--/ Menu header -->

<!-- Navbar shadow -->
<div class="shadow-bottom" [ngClass]="{ 'd-block': isScrolled }"></div>

@if (currentUser) {
  @if (currentUser.subscription) {
    <!-- Main menu -->
    <div class="main-menu-content bg-primary " (scroll)="onSidebarScroll()" ngxScrollbar style="background-image: url('assets/images/bg-pattern.svg'); z-index: 99; overflow-y: auto; height: 65% !important;">
      <ul class="navigation navigation-main bg-primary " layout="vertical" core-menu style="background-image: url('assets/images/bg-pattern.svg'); font-family: 'Almarai-Regular';">
      </ul>
    </div>
    <!--/ Main menu -->
  }
  @else {
    <div class="main-menu-content bg-primary " (scroll)="onSidebarScroll()" ngxScrollbar style="background-image: url('assets/images/bg-pattern.svg'); z-index: 99; overflow-y: auto; height: 65% !important;">
      <ul class="navigation navigation-main bg-primary " layout="vertical" style="background-image: url('assets/images/bg-pattern.svg'); font-family: 'Almarai-Regular';">
        <li>
          <ng-container>
            <!-- item with router url -->
            <a
              class="d-flex align-items-center"
              [routerLink]="appRoutesConstants.login"
            >
              <ng-container *ngTemplateOutlet="itemContent"></ng-container>
            </a>
          
            <ng-template #itemContent>
              <span>
                <img class="mr-1" src="assets/icons/white/Logout.svg" alt="">
              </span>
              <span class="menu-title text-white">تسجيل الخروج</span>
            </ng-template>
          </ng-container>
        </li>
      </ul>
    </div>
  }
}

@if (!isCollapsed) {
  <div class="d-flex justify-content-end align-items-center flex-column" style="position: absolute; bottom: 0; right: 25px;">
    <img style="width: 50%;" src="assets/images/logo-white.svg" alt="">
    <p class="text-white mt-1">جميع الحقوق محفوظة لمنصة كسب</p>
  </div>
}