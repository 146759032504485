export const locale = {
  lang: 'ar',
  data: {
    MENU: {
      DASHBOARD: 'الرئيسية',
      PRODUCTS_MANAGEMENT: 'إدارة المنتجات',
      ORDERS_MANAGEMENT: 'إدارة الطلبات',
      USERS_MANAGEMENT: 'إدارة العملاء',
      PAYMENTS: 'الحركات المالية',
      BILLS_MANAGEMENT: 'إدارة الفواتير',
      CHATS: 'المحادثات',
      NOTIFICATIONS: 'الإشعارات',
      SETTINGS: 'إدارة اللوحة',
      LOGOUT: 'تسجيل الخروج',
      MOTHER_COMPANIES: 'الشركات الأم',
      ALL_COMPANIES: 'كل الشركات',
      PLANS: 'الباقات',
    },
  },
};
