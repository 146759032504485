export const locale = {
  lang: 'ar',

  data: {
    USER_NAME: 'اسم العميل',
    NUMBER_OF_PRODUCTS: 'عدد المنتجات',
    NUMBER_OF_ORDERS: 'عدد الطلبات',
    LAST_ORDER_DATE: 'تاريخ اخر طلب',
    USER_STATUS: 'حالة العميل',
    NUMBER_OF_BOUGHT_PRODUCTS: 'عدد المنتجات المشتراة',
    USER_ORDERS: 'طلبات العميل',
    ORDER_DETAILS: 'تفاصيل الطلب',
  },
};
