export const locale = {
  lang: 'ar',

  data: {
    SEND_NOTIFICATION: 'إرسال إشعار',
    SENT_TO_TYPE: 'نوع المرسل إليه',
    SENT_TO: 'المرسل إليه',
    NOTIFICATION_TITLE: 'عنوان الإشعار',
    NOTIFICATION_CONTENT: 'محتوى الإشعار',

    COMPANY_SELLER_STATUS_CHANGED: 'التغيرات في حالة التجار',
    ORDER_CREATED: 'الطلبات',
    PRODUCT_RATED_BADLY: 'التقييمات السلبية',
    TICKET_STATUS_CHANGED: 'التغيرات في حالات المحادثات',
    CUSTOM_NOTIFICATION_CREATED: 'إشعارات قادمة من لوحة التحكم',
  },
};
