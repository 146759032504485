import {
  Component,
  OnDestroy,
  OnInit,
  HostBinding,
  HostListener,
  ViewEncapsulation,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';

import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { CoreConfigService } from '@core/services/config.service';
import { CoreMediaService } from '@core/services/media.service';

import { coreConfig } from 'app/app-config';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { appRoutesConstants } from 'app/@core/values/app-routes-constants';

import { layoutKeys } from 'app/layout/@core/values/layout-keys';
import { AppUtilsService } from 'app/@core/utils/app-utils.service';
import { LocalStorageService } from 'app/@core/utils/local-storage.service';
import { faCalendarDays } from '@fortawesome/free-regular-svg-icons';
import { faUserCog } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'app/features/auth/@core/utils/auth.service';
import { AuthLoginResponseDto } from 'app/features/auth/data/models/remote/response/auth-login-response-dto.model';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NavbarComponent implements OnInit, OnDestroy {
  public horizontalMenu!: boolean;
  public hiddenMenu!: boolean;

  public coreConfig: any;
  public currentSkin!: string;
  public prevSkin!: string;
  public serverUrl!: string;

  public currentUser!: AuthLoginResponseDto;
  public avatarImage!: string;

  public languageOptions: any;
  public navigation: any;
  public selectedLanguage: any;

  @HostBinding('class.fixed-top')
  public isFixed = false;

  @HostBinding('class.navbar-static-style-on-scroll')
  public windowScrolled = false;

  public appRoutesConst = appRoutesConstants;
  public layoutKeys = layoutKeys;

  // Add .navbar-static-style-on-scroll on scroll using HostListener & HostBinding
  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (
      (window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop > 100) &&
      this.coreConfig.layout.navbar.type == 'navbar-static-top' &&
      this.coreConfig.layout.type == 'horizontal'
    ) {
      this.windowScrolled = true;
    } else if (
      (this.windowScrolled && window.pageYOffset) ||
      document.documentElement.scrollTop ||
      document.body.scrollTop < 10
    ) {
      this.windowScrolled = false;
    }
  }

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {Router} _router
   * @param {AuthService} _authService
   * @param {CoreConfigService} _coreConfigService
   * @param {CoreSidebarService} _coreSidebarService
   * @param {CoreMediaService} _coreMediaService
   * @param {MediaObserver} _mediaObserver
   * @param {TranslateService} _translateService
   */
  constructor(
    private _router: Router,
    private _coreConfigService: CoreConfigService,
    private _coreMediaService: CoreMediaService,
    private _coreSidebarService: CoreSidebarService,
    private _mediaObserver: MediaObserver,
    public _authService: AuthService,
    public _translateService: TranslateService,
    private _appUtilsService: AppUtilsService,
    private _localStorageService: LocalStorageService
  ) {
    this.languageOptions = {
      ar: {
        title: 'arabic',
        flag: 'sy',
      },
    };

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // Public Methods
  // -----------------------------------------------------------------------------------------------------
  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebar(key: any): void {
    this._coreSidebarService.getSidebarRegistry(key)?.toggleOpen();
  }

  /**
   * Set the language
   *
   * @param language
   */
  setLanguage(language: any): void {
    // Set the selected language for the navbar on change
    this.selectedLanguage = language;

    // Use the selected language id for translations
    this._translateService.use(language);

    this._coreConfigService.setConfig(
      { app: { appLanguage: language } },
      { emitEvent: true }
    );
  }

  /**
   * Toggle Dark Skin
   */
  toggleDarkSkin() {
    // Get the current skin
    this._coreConfigService
      .getConfig()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.currentSkin = config.layout.skin;
      });

    if (this.currentSkin === 'dark') {
      this._localStorageService.setJsonValue('skin', 'light');
      this._coreConfigService.setConfig(
        { layout: { skin: 'light' } },
        { emitEvent: true }
      );
    } else {
      this._localStorageService.setJsonValue('skin', 'dark');
      this._coreConfigService.setConfig(
        { layout: { skin: 'dark' } },
        { emitEvent: true }
      );
    }
  }

  /**
   * Logout method
   */
  logout() {
    this._authService.logout();
    this._router.navigate([appRoutesConstants.login]);
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // get the currentUser details from localStorage
    this._authService._currentUserSubject.subscribe((user) => {
      if (user) this.currentUser = user;
      // if(user) {
      //   if(user.image) {
      //     this.avatarImage =
      //       environment.resourcesUrl + '/' + user.image.fullUrl;

      //     this.avatarImage = this.avatarImage.replace('Temp', 'Users')
      //   }
      //   else {
      //     this.avatarImage = '';
      //   }
      // }
    });
    this.serverUrl = environment.serverUrl;

    // Subscribe to the config changes
    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config: any) => {
        this.coreConfig = config;
        this.horizontalMenu = config.layout.type === 'horizontal';
        this.hiddenMenu = config.layout.menu.hidden === true;
        this.currentSkin = config.layout.skin;

        // Fix: for vertical layout if default navbar fixed-top than set isFixed = true
        if (this.coreConfig.layout.type === 'vertical') {
          setTimeout(() => {
            if (this.coreConfig.layout.navbar.type === 'fixed-top') {
              this.isFixed = true;
            }
          }, 0);
        }
      });

    // Horizontal Layout Only: Add class fixed-top to navbar below large screen
    if (this.coreConfig.layout.type == 'horizontal') {
      // On every media(screen) change
      this._coreMediaService.onMediaUpdate
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(() => {
          const isFixedTop = this._mediaObserver.isActive('bs-gt-xl');
          if (isFixedTop) {
            this.isFixed = false;
          } else {
            this.isFixed = true;
          }
        });
    }

    // Set the selected language from default languageOptions
    this.selectedLanguage = _.find(this.languageOptions, {
      id: this._translateService.currentLang,
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
