import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from 'environments/environment';

import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
// import { MetaService } from 'app/main/meta/meta.service';
import { ResponseDto } from 'app/@core/network-models/response-dto.model';
import { LocalStorageService } from 'app/@core/utils/local-storage.service';
import { AuthConfirmEmailRequestDto } from '../../data/models/remote/request/auth-confirm-email-request-dto.model';
import { AuthConfirmPhoneRequestDto } from '../../data/models/remote/request/auth-confirm-phone-request-dto.model';
import { AuthRegisterRequestDto } from '../../data/models/remote/request/auth-register-request-dto.model';
import { AuthLoginResponseDto } from '../../data/models/remote/response/auth-login-response-dto.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements Resolve<any> {
  // ------------------------------- Public Fields -------------------------------

  public currentUser: Observable<AuthLoginResponseDto | null>;
  public onAuthAction!: BehaviorSubject<ResponseDto<any>>;
  public onRegisterAction!: BehaviorSubject<ResponseDto<any>>;
  public onConfirmPhoneAction!: BehaviorSubject<ResponseDto<any>>;

  // ------------------------------- Private Fields -------------------------------

  public _currentUserSubject: BehaviorSubject<AuthLoginResponseDto | null>;
  private _routeParams: any;

  // ------------------------------- Constructor -------------------------------

  constructor(
    private _http: HttpClient,
    private _localStorageService: LocalStorageService
  ) // private _metaService: MetaService,
  {
    this._currentUserSubject = new BehaviorSubject<AuthLoginResponseDto | null>(
      this._localStorageService.getJsonValue('currentUser')
    );

    this.currentUser = this._currentUserSubject.asObservable();

    this.reset();
  }

  // ------------------------------- interface Resolve -------------------------------

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
    this._routeParams = route.params;
    return new Promise<void>((resolve, reject) => {
      Promise.all([
        this.confirmByEmail(),
        this.register(),
        this.confirmByPhone(),
      ]).then(() => {
        resolve();
      }, reject);
    });
  }

  // ------------------------------- Public Functions -------------------------------

  public login(data: any): void {
    this.setUserAtLocalStorage(data);
    this._currentUserSubject.next(data);
  }

  public confirmByEmail(model?: AuthConfirmEmailRequestDto): Promise<any> {
    return new Promise<any>(
      (
        resolve: (value: any | PromiseLike<any>) => any,
        reject: (reason?: any) => void
      ) => {
        let params: string = `?culture=${environment.arabicLocale}`;
        this._http
          .post<any>(
            `${environment.apiUrl}/accounts/ConfirmByEmail/${params}`,
            model
          )
          .subscribe(
            (res: any) => {
              let response = res as ResponseDto<any>;
              this.onAuthAction.next(response);
            },
            (error: any) => {
              this.onAuthAction.error(error);
            }
          );
      }
    );
  }

  public register(model?: AuthRegisterRequestDto): Promise<any> {
    return new Promise<any>(
      (
        resolve: (value: any | PromiseLike<any>) => any,
        reject: (reason?: any) => void
      ) => {
        let params: string = `?culture=${environment.arabicLocale}`;
        this._http
          .post<any>(`${environment.apiUrl}/accounts/register/${params}`, model)
          .subscribe(
            (res: any) => {
              let response = res as ResponseDto<any>;
              if (response.data) {
              }

              this.onRegisterAction.next(response);
            },
            (error: any) => {
              this.onRegisterAction.error(error);
            }
          );
      }
    );
  }

  public confirmByPhone(model?: AuthConfirmPhoneRequestDto): Promise<any> {
    return new Promise<any>(
      (
        resolve: (value: any | PromiseLike<any>) => any,
        reject: (reason?: any) => void
      ) => {
        let params: string = `?culture=${environment.arabicLocale}`;
        this._http
          .post<any>(
            `${environment.apiUrl}/accounts/ConfirmByPhone/${params}`,
            model
          )
          .subscribe(
            (res: any) => {
              let response = res as ResponseDto<any>;
              if (response.data) {
                this.setUserAtLocalStorage(response.data);
              }
              this.onConfirmPhoneAction.next(response);
              this._currentUserSubject.next(response.data);
            },
            (error: any) => {
              this.onConfirmPhoneAction.error(error);
            }
          );
      }
    );
  }

  public logout(): void {
    // remove user from local storage to log user out
    this._localStorageService.removeJsonValue('currentUser');
    // this._localStorageService.removeJsonValue('config')

    // this._metaService.reset();

    // notify // TODO
    this._currentUserSubject.next(null);
  }

  public setUserAtLocalStorage(user: AuthLoginResponseDto): void {
    this._localStorageService.setJsonValue('currentUser', user);
  }

  public get currentUserValue(): AuthLoginResponseDto | null {
    return this._currentUserSubject.value;
  }

  public get isAdmin(): boolean {
    return false;
    // this.currentUser && this._currentUserSubject.value.isManager;
  }

  public get isClient(): boolean {
    return false;
    // this.currentUser && this._currentUserSubject.value.isClient;
  }

  public currentUserSet(value: AuthLoginResponseDto | null): void {
    this._currentUserSubject = new BehaviorSubject<AuthLoginResponseDto | null>(
      value
    );
  }

  public reset(): void {
    this.onAuthAction = new BehaviorSubject<any>(null);
    this.onRegisterAction = new BehaviorSubject<any>(null);
    this.onConfirmPhoneAction = new BehaviorSubject<any>(null);
  }

  // ------------------------------- Private Functions -------------------------------
}
