<!-- app-content-header start -->
<ng-container *ngIf="contentHeader">

  <div class="content-header row">

    <div class="content-header-left col-md-9 col-12 mb-2 pl-0">

      <div class="row breadcrumbs-top">

        <div class="col-12 d-flex">
          <a
            class="nav-link modern-nav-toggle d-block d-xl-none pr-50"
            (click)="toggleSidebar()"
          >
            <i data-feather="menu" class="font-medium-4 text-primary toggle-icon"></i>
          </a>
          <h2 class="content-header-title float-left mb-0 ml-1">
            @if (icon) {
              <img style="width: 31px; height: 31px;" [src]="icon" alt="">
            }
            {{ contentHeader.headerTitle }}
          </h2>

          <!-- app-breadcrumb component -->
          <app-breadcrumb [breadcrumb]="contentHeader.breadcrumb">
          </app-breadcrumb>
          <!--/ app-breadcrumb component -->

        </div>

      </div>

    </div>

    <ng-container *ngIf="contentHeader.actionButton">

      <div class="content-header-right text-md-right col-md-3 col-12 d-md-block d-none">

        <div class="form-group breadcrum-right">

          <div ngbDropdown>

            <button ngbDropdownToggle id="dropdownSettings" class="btn-icon btn btn-primary btn-round btn-sm"
              type="button" rippleEffect>

              <span [data-feather]="'grid'">
              </span>

            </button>


          </div>

        </div>

      </div>

    </ng-container>

  </div>

</ng-container>
<!-- app-content-header end -->